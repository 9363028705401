import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {Button} from 'antd';

function ProductGrid(props) {
  const {products, onItemClick} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      {products
        .slice()
        .sort((a, b) => a.priority - b.priority)
        .map((product, i) => {
          return (
            <ProductItem
              key={i}
              mobile={mobile}
              product={product}
              onClick={(evt) => onItemClick(product, evt)}
            />
          );
        })}
      {new Array(products.length).fill(0).map((item, key) => (
        <div className="filler" key={key} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};

  & > .filler {
    width: calc((1200px - 100px) / 3);
    height: 1px;
    margin: 0 10px;
    padding: 0 10px;
    background-color: transparent;
  }

  @media screen and (max-width: 1200px) {
    & > .filler {
      width: calc((100vw - 100px) / 3);
    }
  }
  @media screen and (max-width: 768px) {
    & > .filler {
      width: calc((100vw - 100px) / 3);
    }
  }

  @media screen and (max-width: 560px) {
    & > .filler {
      width: calc((100vw - 60px));
    }
  }
`;

function ProductItem(props) {
  const {product, onClick, mobile} = props;

  const imageUrl = React.useMemo(() => {
    try {
      return product.images[0].expected_url;
    } catch (err) {
      return '';
    }
  }, [product]);

  return (
    <ItemWrapper mobile={mobile} onClick={onClick}>
      <img src={imageUrl} alt="product" />
      <div className="info">
        <h3 className="name">{product.name}</h3>
        <p className="description">{product.description || ''}</p>
        {product.is_sold_out ? (
          <p className="price" style={{color: '#846e4f'}}>
            已售完
          </p>
        ) : (
          <p className="price">${product.price}</p>
        )}
      </div>
      <div className="view-detail">
        <Button className="view-detail-button">View Detail</Button>
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  background-color: white;
  margin: 10px;
  width: calc((1200px - 100px) / 3);
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: 200ms;
  padding: 10px;
  border: 1px solid transparent;

  & > .view-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    transform: translateY(60px);
    transition: all 400ms ease;

    & > .view-detail-button {
      color: rgba(132, 110, 79, 1);
      border-color: rgba(132, 110, 79, 1);
    }
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border: 1px solid #6c7371;
    & > .view-detail {
      transform: translateY(0);
    }
  }

  & > .info {
    width: 100%;
    padding: 6px 10px;
    margin-top: 10px;
    height: 160px;
    & > .name {
      font-weight: bold;
      height: 53px;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > .description {
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > .price {
      margin-top: 20px;
      font-weight: bold;
    }
  }

  & > img {
    overflow: hidden;
    background-color: #eee;
    width: 100%;
    height: calc((((1200px - 100px) / 3) - 22px) * (545 / 668));
    object-fit: cover;
    transition: 200ms;
  }

  @media screen and (max-width: 1200px) {
    width: calc((100vw - 100px) / 3);
    & > img {
      height: calc((((100vw - 100px) / 3) - 22px) * (545 / 668));
    }
  }
  @media screen and (max-width: 768px) {
    width: calc((100vw - 100px) / 3);
    & > img {
      height: calc((((100vw - 100px) / 3) - 22px) * (545 / 668));
    }
  }

  @media screen and (max-width: 560px) {
    width: calc((100vw - 60px));
    & > img {
      height: calc((((100vw - 60px)) - 22px) * (545 / 668));
    }
  }
`;

export default ProductGrid;
